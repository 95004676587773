import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import useNavigationLinks from "../../hooks/useNavigationLinks";
import { useActivateSearch } from "../../context/ActivateSearchProvider";

// @emotion/css
import { css } from "@emotion/css";

// @mui/material
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// sito components
import SitoImage from "sito-image";

// components
import NavigationChipTab from "../Tab/NavigationChipTab";
import IconBar from "./IconBar";
import SearchBar from "../Search/SearchBar";

// contexts
import { useMode } from "../../context/ModeProvider";

// images
import logoLight from "../../assets/images/logo.png";
import logoDark from "../../assets/images/logo_dark.png";

//react-redux
import { useSelector } from "react-redux";

const Navbar = () => {
    const user = useSelector((state) => state.user);
    const themeCurrent = useSelector((state) => state.useThemePage);
    const isSearchExpanded = useActivateSearch();
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (searchInputRef.current && isSearchExpanded) searchInputRef.current.focus();
    }, [isSearchExpanded]);

    const theme = useTheme();
    const location = useLocation();
    const { pathname: pathName } = location;

    const [pathValue, links] = useNavigationLinks(user?.role_name, pathName);

    return (
        <Box
            sx={{ position: "fixed", width: "100%", top: "0", zIndex: 400, py: "10px" }}
            bgcolor={"background.paper"}
        >
            <Grid container spacing={1}>
                <Grid
                    xs={12}
                    container
                    display="flex"
                    alignItems="center"
                    sx={{ px: { xs: "10px", md: "25px" } }}
                >
                    <Grid
                        xs={2}
                        md={2}
                        lg={2}
                        sx={{ display: isSearchExpanded ? { xs: "none", md: "block" } : "block" }}
                    >
                        <Link
                            to="/"
                            className={css({
                                color: theme.palette.secondary.main,
                                textDecoration: "none",
                            })}
                        >
                            <SitoImage
                                src={themeCurrent == "light" ? logoLight : logoDark}
                                alt="logo"
                                sx={{
                                    width: "110px",
                                    height: "25px",
                                }}
                            />
                        </Link>
                    </Grid>
                    <Grid
                        md
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        <NavigationChipTab links={links} pathValue={pathValue} />
                    </Grid>
                    <Grid xs={12} md={2} xsOffset="auto" display={isSearchExpanded ? "block" : "none"}>
                        <SearchBar />
                    </Grid>
                    <Grid
                        xs={3}
                        md={2}
                        xsOffset="auto"
                        sx={{ display: isSearchExpanded ? "none" : "flex", justifyContent: "end" }}
                    >
                        <IconBar user={user} />
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                    <Divider />
                </Grid>
                <Grid
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ display: { xs: "flex", md: "none" } }}
                >
                    <NavigationChipTab links={links} pathValue={pathValue} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Navbar;
